<template>
  <div :style="{width, height}">
    <!-- 上面大图 -->
    <div class="big-img">
        <template v-if="dataList.length > 0">
            <template v-for="(item, index) in dataList">
                <div :key="index" v-show="index === activeIndex" style="width: 100%; height: 400px;">
                    <img v-if="item.type === 'image'"  :src="'https://resources.holowits.com/'+item.mediaUrl" alt="">
                    <iframe v-else width="100%" height="100%" :src="item.mediaUrl+'?rel=0'" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
            </template>
        </template>
    </div>
    <!-- 下面小图列表 -->
    <div class="small-img-list">
        <div class="img-list" ref="imgList">
            <div class="item-container" ref="itemContainer" :style="{'position': 'absolute', 'display': 'flex', 'left': `${offset}px`}">
                <!-- <div class="left-point"></div> -->
                <div class="img-item" :class="{'img-item_active': activeIndex === index}" v-for="(item, index) in dataList" :key="index" @mouseenter="handleMouseOver(index)">
                    <img :src="'https://resources.holowits.com/'+item.coverUrl" alt="">
                </div>
                <!-- <div class="right-point"></div> -->
            </div>
        </div>
        <div class="button-prev" @click="moveLeft"></div>
        <div class="botton-next" @click="moveRight"></div>
    </div>
  </div>
</template>

<script>
export default {
name: 'swiper',
props: {
    width: {
        type: String,
        default: '100%'
    },
    height: {
        type: String,
        default: '100%'
    },
    dataList: {
        type: Array,
        default: () => []
    }
},
data() {
    return {
        activeIndex: 0,
        offset: 0
    }
},
mounted() {
},
methods: {
    handleMouseOver(value) {
        // console.log(value)
        this.activeIndex = value;
    },
    moveLeft() {
        // 距离父元素左边界距离
        let offsetLeft = this.$refs.itemContainer.offsetLeft;

        if (offsetLeft < 0) {
            this.offset += 100;
        }

        if (offsetLeft > 0) {
            this.offset = 0;
        }
    },
    moveRight() {
        // 距离父元素左边界距离
        let offsetLeft = this.$refs.itemContainer.offsetLeft;
        // 实际宽度
        let scrollWidth = this.$refs.itemContainer.scrollWidth;
        // 父元素显示宽度
        let offsetWidth = this.$refs.imgList.offsetWidth;

        if (scrollWidth < offsetWidth) {
            return false;
        }

        if (scrollWidth - Math.abs(offsetLeft) > offsetWidth) {
            this.offset -= 100;
        }

        if (scrollWidth - Math.abs(offsetLeft) < offsetWidth) {
            this.offset += offsetWidth - (scrollWidth - Math.abs(offsetLeft))
        }
        
    }
}
}
</script>

<style lang="less" scoped>
.left-point, .right-point {
    height: 100%;
    width: 1px;
}

.big-img {
    height: calc(100% - 132px);
    background-color: #f7f7f7;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}
.small-img-list {
    position: relative;
    height: 132px;
    .img-list {
        height: 100%;
        margin: 0 30px;
        padding: 10px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        .item-container {
            transition: all 0.25s;
        }

        .img-item {
            height: 100%;
            width: 142px;
            cursor: pointer;
            will-change: border;
            user-select: none;
            padding: 14px;
            border: 1px solid transparent;
            flex-shrink: 0;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            &+.img-item {
                margin-left: 20px;
            }

            &:hover {
                border: 1px solid var(--themeColor);
            }
        }
        .img-item_active {
            border: 1px solid var(--themeColor);
        }
    }
}
.button-prev, .botton-next {
    position: absolute;
    top: 50%;
    width: calc(44px/44*27);
    height: 44px;
    margin-top: calc(0px - 44px/2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-prev {
    left: 0;
    color: #999;
    &:after {
        font-size: 30px;
        content: "prev";
        font-family: swiper-icons;
        text-transform: none!important;
        letter-spacing: 0;
        font-variant: normal;
        line-height: 1;
    }
}
.botton-next {
    right: 0;
    color: #999;
    &:after {
        font-size: 30px;
        content: "next";
        font-family: swiper-icons;
        text-transform: none!important;
        letter-spacing: 0;
        font-variant: normal;
        line-height: 1;
    }
}
</style>