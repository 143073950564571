<template>
  <div :class="['compare-list', isHide? 'bar-hide':'bar-un-hide']" v-show="isShow">
    <div class="show-box">
      <div class="list">
        <div class="compare-item" v-for="(item, index) in comparisonList" :key="index">
          <div class="compare-item-img">
            <img :src="domainUrl+item.imageUrl | prefixFilter" alt="">
          </div>
          <div class="compare-item-title">{{ item.name }}</div>
          <div class="del-btn">
            <span class="el-icon-close" @click="deleteCompareList(index)"></span>
          </div>
        </div>
      </div>
      <div class="opration-panel">
        <div>
          <el-button round type="primary" style="width: 110px;" @click="compare">Compare</el-button>
        </div>
        <div style="text-align: center; margin-top: 10px;">
          <el-button round type="primary" style="background-color: #ffffff; color: rgba(199, 0, 11, 0.76); width: 110px;" @click="clear">Clear</el-button>
        </div>
      </div>
      <div class="up"></div>
      <div class="down" @click="hideBar">
        <span :class="['el-icon-caret-bottom', isHide? 'is-hide': 'un-hide']"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapMutations,mapActions} from "vuex"
export default {
name: 'CompareList',
data() {
    return {
      isHide: false
    }
},
computed: {
  ...mapState({
    isShow: state => state.compare.comparisonList.length > 0,
    comparisonList: state => state.compare.comparisonList
  })
},
methods: {
  hideBar() {
    this.isHide = !this.isHide
  },
  deleteCompareList(index) {
    this.$store.dispatch("compare/deleteComparisonList", index)
  },
  compare() {
    let routeUrl = this.$router.resolve({
      path: '/product-comparison',
    })
    window.open(routeUrl.href, '_blank');
  },
  clear() {
    this.$store.dispatch("compare/clearComparisonList")
  },
},
filters: {
  prefixFilter(val) {
    if (val) {
      return val.replace('https://resources.holowits.com.sg/', '')
    }
    return val
  }
},
}
</script>

<style lang="scss" scoped>
.bar-hide {
  bottom: calc(-180px + 20px) !important;
}
.bar-un-hide {
  bottom: 0px !important;
}
.compare-list {
    background-color: #ffffff;
    height: 180px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 999;
    box-shadow: 0px 0px 7px 0px #dadbd9;
    transition: all 0.4s;

    .show-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #ffffff;

      .up {
        height: 40px;
        width: 40px;
        position: absolute;
        left: 50%;
        top: -20px;
        // background-color: #000000;
        box-shadow: 0px 0px 7px 0px #dadbd9;
        transform: rotate(45deg) translate(-25%, 50%);
        z-index: -1;
      }
      .down {
        height: 40px;
        width: 40px;
        position: absolute;
        left: 50%;
        top: -20px;
        background-color: #ffffff;
        transform: rotate(45deg) translate(-25%, 50%);
        z-index: 1;
        
        span {
          font-size: 40px;
          transform: rotate(315deg);
          color: rgb(199, 0, 11);
          cursor: pointer;
        }
        .is-hide {
          transform: rotate(135deg);
        }
        .un-hide {
          transform: rotate(315deg);
        }
      }
    }

    .list {
        width: 880px;
        height: 100%;
        // margin: auto;
        margin-right: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    .compare-item {
      width: 200px;
      height: 145px;
      margin-right: 20px;
      position: relative;

      .compare-item-img {
        width: 100%;
        height: 111px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .compare-item-title {
        text-align: center;
        font-size: 12px;
        line-height: 1.4;
        // margin-top: 15px;
        // margin-bottom: 8px;
        font-weight: 600;
        color: #000000;
      }
      .del-btn {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 22px;
        cursor: pointer;
        &:hover {
          color: rgba(199, 0, 11, 0.76);
        }
      }
    }
    .opration-panel {

    }
}
</style>